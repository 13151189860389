.productOffering {
  .card {
    overflow: hidden;
  }

  tr {
    text-align: center;

    .feature {
      text-align: left;
    }

    .feature, .feature-value {
      font-weight: bold;
    }
  }

  a button {
    text-decoration: none;
  }

  thead {
    th, td {
      padding-top: 0;
      margin-right: .75em;
      border: none;
    }

    .head {
      padding: 10px 5px 5px;
      position: relative;
      z-index: 1;
    }

    .content {
      padding-top: 5px;
    }

    .trial-credit-robo {
      background-color: #A2D3E4;
    }

    .my-credit-robo {
      background-color: #66B767;
    }

    .enterprise-credit-robo {
      background-color: #C73890;
    }
  }
}
