//@import '~@coreui/coreui/scss/bootstrap/functions';
//@import '~@coreui/coreui/scss/bootstrap/variables';

.card-hover:hover {
  background: #B1B7C1FF;
  cursor: pointer;
}

.intro-slide .card-body {
  min-height: 150px;
}