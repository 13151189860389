.toggle-email {
  cursor: pointer;

  label {
    cursor: pointer;
  }

  :hover {
    color: #20a8d8;
  }
}