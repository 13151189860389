.introduction {
  .introduction-buy {
    .card-body {
      min-height: 100px
    }
  }

  .introduction-trial {
    .card-body {
      min-height: 200px
    }
  }
}