/* CoreUI styling */
@import "~@coreui/coreui/scss/coreui.scss";

:root {
  --cui-blue: #0d6efd;
  --cui-indigo: #6610f2;
  --cui-purple: #6f42c1;
  --cui-pink: #d63384;
  --cui-red: #dc3545;
  --cui-orange: #fd7e14;
  --cui-yellow: #ffc107;
  --cui-green: #198754;
  --cui-teal: #20c997;
  --cui-cyan: #0dcaf0;
  --cui-black: #000015;
  --cui-white: #fff;
  --cui-gray: #8a93a2;
  --cui-gray-dark: #636f83;
  --cui-gray-100: #ebedef;
  --cui-gray-200: #d8dbe0;
  --cui-gray-300: #c4c9d0;
  --cui-gray-400: #b1b7c1;
  --cui-gray-500: #9da5b1;
  --cui-gray-600: #8a93a2;
  --cui-gray-700: #768192;
  --cui-gray-800: #636f83;
  --cui-gray-900: #4f5d73;
  --cui-primary: #321fdb;
  --cui-secondary: #9da5b1;
  --cui-success: #2eb85c;
  --cui-info: #39f;
  --cui-warning: #f9b115;
  --cui-danger: #e55353;
  --cui-light: #ebedef;
  --cui-dark: #4f5d73;
  --cui-primary-rgb: 50, 31, 219;
  --cui-secondary-rgb: 157, 165, 177;
  --cui-success-rgb: 46, 184, 92;
  --cui-info-rgb: 51, 153, 255;
  --cui-warning-rgb: 249, 177, 21;
  --cui-danger-rgb: 229, 83, 83;
  --cui-light-rgb: 235, 237, 239;
  --cui-dark-rgb: 79, 93, 115;
  --cui-white-rgb: 255, 255, 255;
  --cui-black-rgb: 0, 0, 21;
  --cui-body-color-rgb: 44, 56, 74;
  --cui-body-bg-rgb: 255, 255, 255;
  --cui-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --cui-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --cui-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --cui-body-font-family: var(--cui-font-sans-serif);
  --cui-body-font-size: 1rem;
  --cui-body-font-weight: 400;
  --cui-body-line-height: 1.5;
  --cui-body-color: rgba(44, 56, 74, 0.95);
  --cui-body-bg: #fff;
  --cui-border-width: 1px;
  --cui-border-style: solid;
  --cui-border-color: #d8dbe0;
  --cui-border-color-translucent: rgba(0, 0, 21, 0.175);
  --cui-border-radius: 0.375rem;
  --cui-border-radius-sm: 0.25rem;
  --cui-border-radius-lg: 0.5rem;
  --cui-border-radius-xl: 1rem;
  --cui-border-radius-2xl: 2rem;
  --cui-border-radius-pill: 50rem;
  --cui-heading-color: unset;
  --cui-link-color: #20a8d8;
  --cui-link-hover-color: #2819af;
  --cui-code-color: #d63384;
  --cui-highlight-bg: #fff3cd;
  --cui-breakpoint-xs: 0;
  --cui-breakpoint-sm: 576px;
  --cui-breakpoint-md: 768px;
  --cui-breakpoint-lg: 992px;
  --cui-breakpoint-xl: 1200px;
  --cui-breakpoint-xxl: 1400px;
  --cui-mobile-breakpoint: md;
}

.btn-outline-success {
  --cui-btn-color: #2eb85c;
  --cui-btn-border-color: #2eb85c;
  --cui-btn-hover-color: rgba(255, 255, 255, 0.87);
  --cui-btn-hover-bg: #58c67d;
  --cui-btn-hover-border-color: #43bf6c;
  --cui-btn-focus-shadow-rgb: 46, 184, 92;
  --cui-btn-active-color: rgba(255, 255, 255, 0.87);
  --cui-btn-active-bg: #58c67d;
  --cui-btn-active-border-color: #43bf6c;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: #2eb85c;
  --cui-btn-disabled-bg: transparent;
  --cui-gradient: none;
}

.btn-success {
  --cui-btn-color: rgba(255, 255, 255, 0.87);
  --cui-btn-bg: #2eb85c;
  --cui-btn-border-color: #2eb85c;
  --cui-btn-hover-color: rgba(44, 56, 74, 0.95);
  --cui-btn-hover-bg: #4dc374;
  --cui-btn-hover-border-color: #43bf6c;
  --cui-btn-focus-shadow-rgb: 39, 156, 81;
  --cui-btn-active-color: rgba(255, 255, 255, 0.87);
  --cui-btn-active-bg: #58c67d;
  --cui-btn-active-border-color: #43bf6c;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: rgba(255, 255, 255, 0.87);
  --cui-btn-disabled-bg: #2eb85c;
  --cui-btn-disabled-border-color: #2eb85c;
}

.sidebar-brand {
  background: white;
}

.sidebar-nav {
  .nav-link {
    font-size: 0.875rem;
  }
}

.btn-info, .btn-secondary {
  --cui-btn-color: rgba(255, 255, 255, 0.87);
  --cui-btn-bg: #20a8d8;
  --cui-btn-border-color: #20a8d8;
  --cui-btn-hover-color: rgba(255, 255, 255, 0.87);
  --cui-btn-hover-bg: #0ea1d0;
  --cui-btn-hover-border-color: #0ea1d0;
  --cui-btn-focus-shadow-rgb: 75, 58, 223;
  --cui-btn-active-color: rgba(255, 255, 255, 0.87);
  --cui-btn-active-bg: #20a8d8;
  --cui-btn-active-border-color: #20a8d8;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: rgba(255, 255, 255, 0.87);
  --cui-btn-disabled-bg: #20a8d8;
  --cui-btn-disabled-border-color: #20a8d8;
}

.btn-outline-info {
  --cui-btn-color: #20a8d8;
  --cui-btn-border-color: #20a8d8;
  --cui-btn-hover-color: rgba(255, 255, 255, 0.87);
  --cui-btn-hover-bg: #0ea1d0;
  --cui-btn-hover-border-color: #0ea1d0;
  --cui-btn-focus-shadow-rgb: 50, 31, 219;
  --cui-btn-active-color: rgba(255, 255, 255, 0.87);
  --cui-btn-active-bg: #20a8d8;
  --cui-btn-active-border-color: #20a8d8;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: #20a8d8;
  --cui-btn-disabled-bg: transparent;
  --cui-gradient: none;
}

.form-check-input:checked {
  background-color: #20a8d8;
  border-color: #20a8d8;
}

/** JIRA Issue collector button */
#atlwdg-trigger {
  background: #3CB371;
  color: white !important;
  font-size: 12px;
}

.dropdown-item:active {
  background-color: #20a8d8;
  border-color: #20a8d8;
}

.card-header-actions {
  display: inline-block;
  float: right;
  margin-right: -0.25rem;
}